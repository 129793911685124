import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './Slideshow.scss'; // Custom styles for the slideshow

const Slideshow = () => {
    // function to detect if on mobile device

    const images = [
        {
            original: '/images/car1.jpg',
            description: 'Car before repair',
            originalAlt: 'Car not repaired',


        },
        {
            original: '/images/car1done.jpg',
            description: 'Car after repair',
            originalAlt: 'Car repaired',


        },

        {
            original: '/images/car2done.webp',
            originalAlt: 'car we worked on',


        },
        {
            original: '/images/car3done.webp',
            originalAlt: 'car we worked on',

        },
        {
            original: '/images/car4.webp',

            originalAlt: 'car we worked on',
        },
        {
            original: '/images/team.jpg',

            description: 'Our Team',
            originalAlt: 'Our Team in action',
        }

    ];

    return (
        <div className="slideshow-container">
            <ImageGallery
                items={images}
                showPlayButton={false}
                slideInterval={15000}
                showThumbnails={false}
                showdots={true}
                showFullscreenButton={false}
                autoPlay={true}

            />
        </div>
    );
};

export default Slideshow;
