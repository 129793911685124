import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        // Delay the scroll slightly to ensure page render
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 3);

        return () => clearTimeout(timer);
    }, [pathname]);

    return null;
};
