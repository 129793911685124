import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {faCar, faSmile} from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <ul className="footer-links">
                    <li>
                        <a href="https://search.google.com/local/writereview?placeid=ChIJqSpcybxis4kRyoJzpC35ewY"
                           target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGoogle}/> Google Review
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/goldwrenchauto/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook}/> Facebook
                        </a>
                    </li>
                    <li>
                        <Link to="/contact"><FontAwesomeIcon className="FontAwesomeIcon" icon={faSmile}/> Contact Us</Link>
                    </li>
                    <li>
                        <Link to="/about"> <FontAwesomeIcon className="FontAwesomeIcon" icon={faCar} /> About</Link>
                    </li>
                    <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to="/terms">Terms of Use</Link>
                    </li>

                </ul>
                <p className="footer-text">© {new Date().getFullYear()} Goldwrench Automotive. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
