import React, {lazy, Suspense} from 'react';
import './home.scss';
import '../components/business-info.scss'
import Slideshow from "../components/slideshow";
import {Helmet} from "react-helmet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
const LazyVideoPlayer = lazy(() => import('../components/videoplayer'));

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Welcome to Goldwrench Automotive - Excellence in Auto Service</title>
                <meta name="description" content="Goldwrench Automotive brings unparalleled expertise in auto repair and maintenance. Discover our services and commitment to quality on our homepage." />
                <meta name="keywords" content="Goldwrench Automotive, auto repair, car service, Waynesboro automotive, vehicle maintenance, auto care" />
            </Helmet>
            <div className="business-info" style={
                {
                    // no spacing at top
                    paddingTop: 0
                }
            }>
                <h2><FontAwesomeIcon icon={faEye}/> Discover Our Vision</h2>
                <p className="video-description">
                    Dive into our journey – watch this short video to understand our mission and what drives us.
                </p>
                <Suspense fallback={<div>Loading Video...</div>}>
                    <LazyVideoPlayer />
                </Suspense>
            </div>
            <div className="Home">
                <h2><FontAwesomeIcon icon= {faMapLocationDot}/> Explore Our Latest Projects</h2>
                <p className="slideshow-description">
                    Take a visual tour through our recent projects. See the transformations and our team in action.
                </p>
                <Slideshow></Slideshow>
            </div>
        </>
    );
};

export default Home;
