import React, {useEffect, useRef, useState} from 'react';
import './HeaderBar.scss';

import bgProducts from '../assets/bgproducts.png';

import napaAutoCare from '../assets/napa-autocare-horizontal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faTimes,
    faPhone,
    faMapMarkerAlt,
    faHome,
    faClipboardCheck,
    faTools, faTruckPickup, faDotCircle
} from '@fortawesome/free-solid-svg-icons';

import {NavLink, useLocation} from "react-router-dom";


const isIOS = () => {
    return (
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
};

const HeaderBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const menuRef = useRef();
    const menuButtonRef = useRef(); // Ref for the menu button

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location]);



    const backgroundStyle = {
        backgroundImage: windowWidth > 768 ? "url('images/bg-header.webp')" : "url('images/bg-header-mobile.webp')"
    };

    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target) && !menuButtonRef.current.contains(e.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        const eventType = 'ontouchstart' in window ? 'touchstart' : 'mousedown';

        if (isMenuOpen) {
            document.addEventListener(eventType, handleOutsideClick);
        } else {
            document.removeEventListener(eventType, handleOutsideClick);
        }

        return () => {
            document.removeEventListener(eventType, handleOutsideClick);
        };
    }, [isMenuOpen]);


    const iosMapLink = "https://maps.apple.com/?address=588%20E%20Side%20Hwy,%20Waynesboro,%20VA%20%2022980,%20United%20States&auid=10316907240851707088&ll=38.111308,-78.857367&lsp=9902&q=Goldwrench%20Automotive&t=m";
    const googleMapLink = "https://www.google.com/maps/place/GOLDWrench+Automotive/@38.1113436,-78.8598249,17z/data=!3m1!4b1!4m6!3m5!1s0x89b362bcc95c2aa9:0x67bf92da47382ca!8m2!3d38.1113394!4d-78.85725!16s%2Fg%2F1vgw9hqh?entry=ttu";
    const ServiceButtons = location.pathname === '/' ? (
        <div className="service-buttons">
            <NavLink  to="/stateinspection"  className={({ isActive }) => isActive ? 'active' : undefined}>
                <button className="service-btn">  <FontAwesomeIcon icon = {faClipboardCheck}></FontAwesomeIcon> STATE INSPECTION</button>
            </NavLink>
            <NavLink  to="/autorepairs"  className={({ isActive }) => isActive ? 'active' : undefined}>
                <button className="service-btn"><FontAwesomeIcon icon = {faTools}></FontAwesomeIcon> AUTO REPAIR</button>
</NavLink>
                <NavLink  to="/towingservice"  className={({ isActive }) => isActive ? 'active' : undefined}>
                    <button className="service-btn"><FontAwesomeIcon icon = {faTruckPickup}></FontAwesomeIcon> TOWING</button>
                </NavLink>
                <NavLink  to="/tires"  className={({ isActive }) => isActive ? 'active' : undefined}>
                    <button className="service-btn"><FontAwesomeIcon icon = {faDotCircle}></FontAwesomeIcon> TIRES</button>
                </NavLink>


        </div>
) : null;

    //if the pathname changes, close the menu
    useEffect(() => {
        setIsMenuOpen(false);
    }, [location]);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <div className="header-container" style={backgroundStyle}>
            <header className="HeaderBar">
                <button className="menu-icon" onClick={toggleMenu} ref={menuButtonRef}>
                    <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="2x"/>
                    <span   className="visually-hidden">>Tabs</span>
                </button>
                <nav className={isMenuOpen ? "active" : ""} ref={menuRef}>
                    <ul>
                        <li>
                            <NavLink to="/" className={({isActive}) => isActive ? 'active' : undefined}>
                                {
                                    window.innerWidth > 768
                                        ? <><FontAwesomeIcon icon={faHome}/><span
                                            className="visually-hidden">Home</span></>
                                        : "HOME"
                                }
                            </NavLink>
                        </li>

                        <li><NavLink to="/autorepairs" className={({isActive}) => isActive ? 'active' : undefined}>AUTO
                            REPAIRS</NavLink></li>
                        <li><NavLink to="/towingservice"
                                     className={({isActive}) => isActive ? 'active' : undefined}>TOWING</NavLink></li>
                        <li><NavLink to="/tires"
                                     className={({isActive}) => isActive ? 'active' : undefined}>TIRES</NavLink></li>
                        <li><NavLink to="/stateinspection" className={({isActive}) => isActive ? 'active' : undefined}>STATE
                            INSPECTION</NavLink></li>
                        <li><NavLink to="/about"
                                     className={({isActive}) => isActive ? 'active' : undefined}>ABOUT</NavLink></li>
                        <li><NavLink to="/contact"
                                     className={({isActive}) => isActive ? 'active' : undefined}>CONTACT</NavLink></li>
                    </ul>
                </nav>

                <div className="right-icons" style={{
                    float: 'right'
                }}>
                    <div className="contact-icons" style={{paddingRight: "20px"}}>
                        <a href="tel:+15409496835">
                        <FontAwesomeIcon icon={faPhone} size="2x" color="green"/>
                            <span className="icon-label" style={{paddingRight: "20px"}}>Call Us</span>
                        </a>
                        <a href={isIOS() ? iosMapLink : googleMapLink}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="red"/>
                            <span className="icon-label">Map to us</span>
                        </a>
                    </div>


                </div>

            </header>
            <div className="branding">
                <div className="HeaderBar-logo">
                    <NavLink to="/" className={({isActive}) => isActive ? 'active' : undefined}> <img
                        src="images/GoldWrench_logo.webp" alt="Goldwrench Automotive"/></NavLink>
                </div>
                <div className="partner-logos">
                    <NavLink to="/bgproducts" className={({isActive}) => isActive ? 'active' : undefined}> <img
                        src={bgProducts} alt="BG Products"/></NavLink>
                    <NavLink to="/napa" className={({isActive}) => isActive ? 'active' : undefined}><img
                        src={napaAutoCare} alt="NAPA AutoCare"/></NavLink>
                </div>

            </div>
            {ServiceButtons}
        </div>
    );
};

export default HeaderBar;
