import React from 'react';
import './towingservice.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhoneAlt, faMapMarkerAlt, faTruckPickup, faSmile} from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";

function TowingService() {
    return (
        <>
            <div className="towing-service-container">
                <Helmet>
                    <title>Reliable Towing Services - Goldwrench Automotive</title>
                    <meta name="description"
                          content="Dependable towing services by Goldwrench Automotive. Quick response and professional assistance in Waynesboro, VA, and surrounding areas for emergencies and vehicle recovery."/>
                    <meta name="keywords"
                          content="towing services, Goldwrench Automotive, roadside assistance, vehicle recovery, emergency towing, Waynesboro VA towing"/>
                </Helmet>
                <h2><FontAwesomeIcon icon={faTruckPickup}/> Towing Services</h2>
                <p>Are you looking for a quick-to-respond towing company? If your vehicle breaks down and you're stuck
                    on
                    the roadside, or you've been involved in an automobile accident, contact Goldwrench Automotive for
                    reliable towing service.

                    Enjoy a discount on repairs when you tow your vehicle with GOLDWrench Towing to our shop!</p>

                <div className="call-now">
                    <FontAwesomeIcon icon={faPhoneAlt} className="call-icon"/>
                    <a href="tel:+15409496835">
                        <h2>Call Now: (540) 949-6835</h2>
                    </a>
                </div>
            </div>
            <div className="towing-service-container">
                <h2><FontAwesomeIcon icon={faSmile}/> Why Us </h2>

                <p>Over the years, we've built a solid reputation in the community by providing trusted and efficient
                    towing
                    service in:</p>
            <ul className="areas-served">
                <li><FontAwesomeIcon icon={faMapMarkerAlt}/> Waynesboro, VA</li>
                <li><FontAwesomeIcon icon={faMapMarkerAlt}/> Crimora, VA</li>
                <li><FontAwesomeIcon icon={faMapMarkerAlt}/> Stuarts Draft, VA</li>
                <li><FontAwesomeIcon icon={faMapMarkerAlt}/> Fishersville, VA</li>
                <li><FontAwesomeIcon icon={faMapMarkerAlt}/> Surrounding areas</li>
            </ul>
            <p>Our well-trained staff will help you solve your towing and vehicle recovery needs in an efficient and
                professional manner.</p>
        </div>

    </>
    );
}

export default TowingService;
