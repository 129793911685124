import React from 'react';
import './stateinspection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCar, faTachometerAlt, faLightbulb,
    faWheelchair, faGasPump, faOilCan,
    faExclamationTriangle, faPhoneAlt, faClipboardCheck, faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";

function StateInspection() {
    return (
        <>
        <div className="state-inspection-container">
            <Helmet>
                <title>Virginia State Vehicle Inspection - Goldwrench Automotive</title>
                <meta name="description"
                      content="Goldwrench Automotive offers comprehensive Virginia state vehicle inspections to ensure your vehicle's safety and compliance with state regulations."/>
                <meta name="keywords"
                      content="state vehicle inspection, Virginia safety compliance, Goldwrench Automotive, car inspection services"/>
            </Helmet>
            <h2><FontAwesomeIcon icon={faClipboardCheck}/> Virginia State Vehicle Inspection</h2>
            <p>Ensure your vehicle's safety and compliance with Virginia state regulations. Goldwrench Automotive offers
                thorough state vehicle inspections.</p>
            <div className="call-now">
                <FontAwesomeIcon icon={faPhoneAlt} className="call-icon"/>
                <a href="tel:+15409496835">
                    <h2>Call Now: (540) 949-6835</h2>
                </a>
            </div>

        </div>
    <div className={"state-inspection-container"}>
        <div className="inspection-details">
            <h2><FontAwesomeIcon icon={faClipboardList}/> Inspection Coverage:</h2>
            <ul>
                <li><FontAwesomeIcon icon={faCar}/> Vehicle Identification Number</li>
                <li><FontAwesomeIcon icon={faTachometerAlt}/> Brakes</li>
                <li><FontAwesomeIcon icon={faLightbulb}/> Lights and Signals</li>
                <li><FontAwesomeIcon icon={faWheelchair}/> Steering and Suspension</li>
                <li><FontAwesomeIcon icon={faGasPump}/> Fuel Systems</li>
                <li><FontAwesomeIcon icon={faOilCan}/> Exhaust System</li>
                <li><FontAwesomeIcon icon={faExclamationTriangle}/> Tires, Wheels, and Rims</li>
                <li><FontAwesomeIcon icon={faClipboardList}/>All other requirments from the State</li>
            </ul>
        </div>
    </div>
    </>
)
    ;
}

export default StateInspection;
