import React from 'react';
import './contact.scss';
import {Helmet} from "react-helmet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap, faPhone, faSmile} from "@fortawesome/free-solid-svg-icons";

const BusinessInfo = () => {
    const isIOS = () => {
        return (
            /iPad|iPhone|iPod/.test(navigator.userAgent) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        );
    };
    const iosMapLink = "https://maps.apple.com/?address=588%20E%20Side%20Hwy,%20Waynesboro,%20VA%20%2022980,%20United%20States&auid=10316907240851707088&ll=38.111308,-78.857367&lsp=9902&q=Goldwrench%20Automotive&t=m";
    const googleMapLink = "https://www.google.com/maps/place/GOLDWrench+Automotive/@38.1113436,-78.8598249,17z/data=!3m1!4b1!4m6!3m5!1s0x89b362bcc95c2aa9:0x67bf92da47382ca!8m2!3d38.1113394!4d-78.85725!16s%2Fg%2F1vgw9hqh?entry=ttu";
    return (
        <div className="business-info">
            <Helmet>
                <title>Contact Goldwrench Automotive - Get in Touch</title>
                <meta name="description" content="Contact Goldwrench Automotive for top-quality auto repair services. Visit us in Waynesboro, VA or call today for professional assistance." />
                <meta name="keywords" content="Goldwrench contact, automotive services, auto repair, Waynesboro VA, car maintenance, contact information" />
            </Helmet>
            <section>
                <h2><FontAwesomeIcon icon={faSmile}/> Contact GoldWrench Automotive </h2>
                <a href={isIOS() ? iosMapLink : googleMapLink} style={{paddingBottom: "10px"}}><p><FontAwesomeIcon icon = {faMap}></FontAwesomeIcon> 588 Eastside Highway,
                    Waynesboro, VA 22980</p></a>
                <div style={{paddingBottom: "10px"}}>

                </div>
                    <a href="tel:+15409496835" >
                        <p><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> (540) 949-6835</p>
                </a>
            </section>
            <section>
                <h2 style = {{paddingTop: "10px"}}>Operating Hours</h2>
                <ul>
                    <li><strong>MON-FRI:</strong> 8:00 AM - 5:00 PM</li>
                    <li><strong>SAT:</strong> Closed</li>
                    <li><strong>SUN:</strong> Closed</li>
                </ul>
            </section>
        </div>
    );
};

export default BusinessInfo;
